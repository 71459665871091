import { Link } from 'gatsby';
import React from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { ResultProps } from './HeaderSearch';

interface Props {
  result: ResultProps;
  reset: Function;
}

export default function HeaderSearchResult({ result, reset }: Props) {
  return (
    <li key={`${result.id}`} className="search-results__entry post-entry">
      <Link
        to={result.path}
        className="search-results__entry__inner"
        onClick={() => reset()}
        state={{ tab: result.tab }}
      >
        <p className="search-results__entry__title post-entry__title small">
          {result.title}
          {result.pageTitle && result.pageTitle !== result.title && (
            <span className="page-title">
              &nbsp;
              <MdChevronLeft /> {result.pageTitle}
            </span>
          )}
        </p>
      </Link>
    </li>
  );
}
