import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Social from './Social';
import ApplyNowCta from './ApplyNowCta';
// import { LogoCrest } from './Logo';

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__cta">
              <ApplyNowCta />
            </div>
            <div className="footer__box">
              <div className="footer__box__inner push-right">
                <div className="footer__logo">
                  <StaticImage
                    src="../images/logo-crest.png"
                    alt="Augustine Institute"
                    loading="eager"
                    objectFit="contain"
                    placeholder="none"
                  />
                </div>
                <div className="footer__social">
                  <Social />
                  <div className="footer__links">
                    <Link to="/privacy-policy/" className="small">
                      Privacy Policy
                    </Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to="/cookie-policy/" className="small">
                      Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
