export const primaryMenu = [
  {
    path: '/',
    label: 'Courses',
  },
  {
    path: '/online-or-on-campus/',
    label: 'Online or On-Campus',
  },
  {
    path: '/admissions/',
    label: 'Admissions',
  },
  {
    path: '/costs/',
    label: 'Costs',
  },
  {
    path: '/degree-requirements/',
    label: 'Degree Requirements',
  },
];

export const secondaryMenu = [
  {
    path: '/about/',
    label: 'About',
  },
  {
    path: '/professors/',
    label: 'Professors',
  },
  {
    path: '/testimonials/',
    label: 'Testimonials',
  },
];
