import { RefObject, useEffect } from 'react';
import { useWindowSize } from './useWindowSize';

export default function useOverflowScroll(
  wrapperRef: RefObject<HTMLDivElement>,
  innerRef: RefObject<HTMLDivElement>
) {
  function checkOverflow() {
    const wrapper = wrapperRef.current;
    const inner = innerRef.current;

    if (wrapper && inner) {
      if (inner.scrollWidth > inner.clientWidth - 5) {
        if (inner.scrollLeft > 0) {
          wrapper.classList.add('overflow-scroll--l');
        } else {
          wrapper.classList.remove('overflow-scroll--l');
        }

        if (inner.scrollLeft < inner.scrollWidth - inner.offsetWidth - 10) {
          wrapper.classList.add('overflow-scroll--r');
        } else {
          wrapper.classList.remove('overflow-scroll--r');
        }
      } else {
        wrapper.classList.remove('overflow-scroll--r', 'overflow-scroll--l');
      }
    }
  }

  useWindowSize(true, () => checkOverflow());

  useEffect(() => {
    checkOverflow();
    const el = innerRef.current;

    if (typeof window !== 'undefined' && el) {
      // window.addEventListener('resize', handleWindowResize);
      el.addEventListener('scroll', checkOverflow);
      return () => {
        // window.removeEventListener('resize', handleWindowResize);
        el.removeEventListener('scroll', checkOverflow);
      };
    }
  }, [wrapperRef, innerRef]);
}
