import { motion, AnimatePresence } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import useFlexSearch from '../hooks/useFlexSearch';
import useOnClickOutside from '../hooks/useOnClickOutside';
import HeaderSearchResult from './HeaderSearchResult';

interface Inputs {
  search: string;
}

export interface ResultProps {
  id: string;
  title: string;
  pageTitle: string;
  path: string;
  tab?: string;
}

export default function HeaderSearch() {
  const { register, handleSubmit, watch, reset } = useForm();
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);

  const searchForm = useRef<HTMLFormElement>(null);

  // Search
  const query = watch('s');
  const { localSearchPages } = useStaticQuery(graphql`
    query {
      localSearchPages {
        publicIndexURL
        publicStoreURL
      }
    }
  `);
  const indexURL = localSearchPages.publicIndexURL;
  const storeURL = localSearchPages.publicStoreURL;
  const searchResults = useFlexSearch(query, indexURL, storeURL);

  // Search Results

  const [searchResultsOpen, setSearchResultsOpen] = useState(!!query);

  useOnClickOutside(searchForm, () => {
    setSearchResultsOpen(false);
    reset(['s']);
  });

  useEffect(() => {
    setSearchResultsOpen(true);
  }, [query]);

  const searchFormRef = useRef<HTMLFormElement>(null);

  useOnClickOutside(searchFormRef, () => setSearchResultsOpen(false));

  return (
    <form onSubmit={(e) => e.preventDefault()} ref={searchFormRef}>
      <label htmlFor="site-search">
        <input
          id="site-search"
          type="search"
          placeholder="Search"
          {...register('s', { required: true })}
          onFocus={() => {
            if (query) {
              setSearchResultsOpen(true);
            }
          }}
        />
        <span className="visually-hidden">Search</span>
        <div className="input-underline" />
      </label>
      <span className="search-icon">
        <FaSearch title="Search" />
      </span>
      <AnimatePresence>
        {searchResultsOpen && searchResults && (
          <motion.div
            className="search-results"
            initial="initial"
            animate="open"
            exit="exit"
            variants={{
              initial: {
                opacity: 0,
                rotateX: 10,
              },
              open: {
                opacity: 1,
                rotateX: 0,
              },
              exit: {
                opacity: 0,
                rotateX: 10,
              },
            }}
          >
            {searchResults.length > 0 && (
              <ul>
                {searchResults.length > 0 &&
                  searchResults.map((result: ResultProps) => (
                    <HeaderSearchResult
                      key={`${result.id}`}
                      result={result}
                      reset={reset}
                    />
                  ))}
              </ul>
            )}
            {searchResults.length === 0 && (
              <div className="search-results__entry">
                <p className="search-results__entry__inner">
                  <small>No results found.</small>
                </p>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  );
}
