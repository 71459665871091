import { useState, useEffect, useMemo } from 'react';
import FlexSearch from 'flexsearch';

export default function useFlexSearch(
  query: string,
  indexURL: string,
  storeURL: string,
  searchOptions: number | Object = {} // TODO: Check what's allowed here if passed as an Object
) {
  const [index, setIndex] = useState(null);
  const [providedIndex, setProvidedIndex] = useState<string | null>('');
  const [store, setStore] = useState({});

  useEffect(() => {
    if (!indexURL && !storeURL)
      console.warn(
        'A FlexSearch index URL and store URL was not provided. Your search results will be empty.'
      );
    else if (!indexURL)
      console.warn(
        'A FlexSearch index URL was not provided. Your search results will be empty.'
      );
    else if (!storeURL)
      console.warn(
        'A FlexSearch store URL was not provided. Your search results will be empty.'
      );
  }, [indexURL, storeURL]);

  useEffect(() => {
    // Search
    if (query && (!providedIndex || !store)) {
      Promise.all([
        fetch(indexURL).then((response) => response.text()),
        fetch(storeURL).then((response) => response.json()),
      ]).then(([fetchedIndex, fetchedStore]) => {
        setProvidedIndex(fetchedIndex);
        setStore(fetchedStore);
      });
    }
  }, [query, providedIndex, store]);

  useEffect(() => {
    if (!providedIndex) {
      setIndex(null);
      return;
    }
    // console.log(providedIndex);
    if (providedIndex instanceof FlexSearch) {
      setIndex(providedIndex);
      return;
    }

    const importedIndex = FlexSearch.create();
    importedIndex.import(providedIndex);

    setIndex(importedIndex);
  }, [providedIndex]);

  return useMemo(() => {
    if (!query || !index || !store) return null;

    const rawResults = index.search(query, searchOptions);

    return rawResults.map((id) => store[id]);
  }, [query, index, store]);
}
