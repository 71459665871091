// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admissions-tsx": () => import("./../../../src/pages/admissions.tsx" /* webpackChunkName: "component---src-pages-admissions-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-costs-tsx": () => import("./../../../src/pages/costs.tsx" /* webpackChunkName: "component---src-pages-costs-tsx" */),
  "component---src-pages-degree-requirements-tsx": () => import("./../../../src/pages/degree-requirements.tsx" /* webpackChunkName: "component---src-pages-degree-requirements-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-or-on-campus-tsx": () => import("./../../../src/pages/online-or-on-campus.tsx" /* webpackChunkName: "component---src-pages-online-or-on-campus-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-professors-tsx": () => import("./../../../src/pages/professors.tsx" /* webpackChunkName: "component---src-pages-professors-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

