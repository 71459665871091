import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import HeaderSearch from './HeaderSearch';
// import { LogoType } from './Logo';
import { useWindowSize } from '../hooks/useWindowSize';
import useOnClickOutside from '../hooks/useOnClickOutside';
import ApplyNowCta from './ApplyNowCta';
import { OverflowScroll } from './OverflowScroll';
import { primaryMenu, secondaryMenu } from '../data/menu';
import MenuItem from './MenuItem';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const mobileMenuWrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize(); // Window Width

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }

    if (menuOpen && width && width > 1024) {
      setMenuOpen(false);
    }
  }, [menuOpen, width]);

  useOnClickOutside(mobileMenuWrapperRef, () => setMenuOpen(false));

  // Menu Scroll
  const [menuActive, setMenuActive] = useState();

  return (
    <>
      <header className="container header">
        <div className="header__top">
          <a href="https://www.augustineinstitute.org/" className="logo">
            <div className="hide-xs show-sm">
              <StaticImage
                src="../images/logo-full.png"
                alt="Augustine Institute"
                loading="eager"
                objectFit="contain"
                placeholder="none"
              />
            </div>
            <div className="hide-sm">
              <StaticImage
                src="../images/logo-crest.png"
                alt="Augustine Institute"
                loading="eager"
                objectFit="contain"
                placeholder="none"
              />
            </div>
          </a>
          <HeaderSearch />
        </div>
        <div className="header__bottom">
          <div className="row">
            <div className="col-xs-12 col-md-7 col-lg-offset-1">
              <div className="header__ma">
                <div className="header__ma__title">
                  <h1>Master of Arts: Theology</h1>
                  <ApplyNowCta />
                </div>
                <div className="header__ma__nav">
                  <nav className="menu">
                    <OverflowScroll scrollToElement={menuActive}>
                      <ul>
                        {primaryMenu.map((menu, i) => (
                          <MenuItem
                            key={`primary-menu-${i}`}
                            menu={menu}
                            setMenuActive={setMenuActive}
                          />
                        ))}
                      </ul>
                    </OverflowScroll>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-5 col-lg-4">
              <div className="header__ai">
                <div className="header__ai__inner push-right">
                  <h2 className="h3">Augustine Institute</h2>
                  <nav className="menu">
                    <OverflowScroll>
                      <ul>
                        {secondaryMenu.map((menu, i) => (
                          <MenuItem key={`secondary-menu-${i}`} menu={menu} />
                        ))}
                      </ul>
                    </OverflowScroll>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
