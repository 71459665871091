import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router'; // eslint-disable-line import/no-unresolved

interface Props {
  menu: {
    path: string;
    label: string;
  };
  setMenuActive?: Function;
}

export default function MenuItem({ menu, setMenuActive }: Props) {
  const { pathname } = useLocation();
  const { path, label } = menu;
  const menuItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (setMenuActive instanceof Function) {
      if (pathname === path && menuItemRef.current) {
        setMenuActive(menuItemRef);
      }
    }
  }, [pathname]);

  return (
    <li ref={menuItemRef} className="menu-item">
      <Link to={path}>{label}</Link>
    </li>
  );
}
