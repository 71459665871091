import React, { useContext } from 'react';
import { IoArrowForwardSharp } from 'react-icons/io5';
import { ModalContext } from './ModalContext';

export default function ApplyNowCta() {
  const { setModal } = useContext(ModalContext);

  return (
    <>
      <button
        type="button"
        className="button button--cta"
        onClick={() => {
          setModal({
            modalOpen: true,
            children: (
              <>
                <h1 style={{ marginBottom: '.75em' }}>Apply Now</h1>
                <iframe
                  title="Apply"
                  src="https://augustine.populiweb.com/router/admissions/onlineapplications/index?application_form=35726&source=101607"
                  className="apply-iframe"
                  style={{
                    width: '100%',
                    minWidth: '100%',
                    height: '760px',
                    display: 'flex',
                  }}
                  frameBorder="0"
                />
              </>
            ),
          });
        }}
      >
        <span>
          Apply Now
          <IoArrowForwardSharp />
        </span>
      </button>
    </>
  );
}
