import React from 'react';
import {
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramLine,
  RiYoutubeFill,
} from 'react-icons/ri';
import {
  GrFacebookOption,
  GrTwitter,
  GrInstagram,
  GrYoutube,
} from 'react-icons/gr';

export default function Social() {
  return (
    <div className="social">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/AugustineInst"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiFacebookFill title="Facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/augustineinst"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiTwitterFill title="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/augustineinst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiInstagramLine title="Instagram" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCViR8Uk46_06QjqkTNlVu2Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiYoutubeFill title="YouTube" />
          </a>
        </li>
      </ul>
    </div>
  );
}
